import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Input, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link, useParams } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


function PopularCompanies() {

    const { id } = useParams()
    const [user, setUser] = useState();

    const [products, setProducts] = useState([]);
    const [title, setTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [comps, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/restaurants`).then(({ data }) => {
            setCompanies(data)
            //console.log(data)
            setLoading(true);
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, [])


    return (
        <>

            <div className="row title" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <h5>Popular </h5>
                </div>
            </div>

            {
                loading ?
                    comps.length > 0 && (
                        comps.map((row, key) => (
                            <Link style={{ textDecoration: "none" }} to={`/companydeals/${row.res_id}`}>
                                <div className="row shadow border-radius-15 mydeal-box" style={{ marginBottom: "20px", overflow: "hidden", margin:"10px" }}>
                                    <div className="col-3" style={{ padding: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>
                                            <img
                                                className="border-radius-15"
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    border: "1px solid #ddd",
                                                    borderRadius: "15px",
                                                    marginLeft: "5px"
                                                }}
                                                alt="Sample"
                                                src={row.image}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-9" style={{ padding: "15px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <p style={{ color: "#2B2B2B", margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{row.res_name}</p>
                                        <p style={{ color: "#666", fontSize: "12px", margin: "5px 0" }}>{row.address}</p>
                                        <p style={{ color: "#FF1843", fontSize: "14px", margin: "0", fontWeight: "500" }}>Available Deals</p>
                                    </div>
                                </div>
                            </Link>

                        ))) : <Spinner animation="border" />
            }
        </>
    )
}
export default PopularCompanies;