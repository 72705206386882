// src/HomeScreenPopup.js
import React, { useState, useEffect } from 'react';

const HomeScreenPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            // Store the event so it can be triggered later.
            window.deferredPrompt = e;
            setIsVisible(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleAddToHomeScreen = () => {
        if (window.deferredPrompt) {
            window.deferredPrompt.prompt();
            window.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User added to home screen');
                } else {
                    console.log('User did not add to home screen');
                }
                window.deferredPrompt = null;
                setIsVisible(false);
            });
        }
    };

    return (
        isVisible && (
            <div style={popupStyle}>
                {/* <p>Do you want to add this app to your home screen?</p> */}
                <button style={{
                    color: '#fff',
                    padding: '5px 15px',
                    margin: '2px 2px 0',
                    border: 'none',
                    borderRadius: '8px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, transform 0.1s',
                    backgroundColor: '#0d47a1', // Changed to dark blue
                }} onClick={handleAddToHomeScreen}>Add to your home screen</button>
                <button style={{
                    color: '#fff',
                    padding: '5px 15px',
                    margin: '2px 2px 0',
                    border: 'none',
                    borderRadius: '8px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, transform 0.1s',
                    backgroundColor: '#424242', // Changed to dark grey
                }} onClick={() => setIsVisible(false)}>X</button>
            </div>
        )
    );
};

const popupStyle = {
    position: 'fixed',
    bottom: '15%',
    left: '40%',
    transform: 'translate(-40%, 40%)',
    backgroundColor: '#e3f2fd', // Light blue background
    border: '2px solid #2196f3', // Darker blue border
    padding: '5px',
    borderRadius: '15px',
    boxShadow: '0 4px 20px rgba(33, 150, 243, 0.25)', // Blue-tinted shadow
    color: '#0d47a1', // Dark blue text color
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    textAlign: 'center',
};

export default HomeScreenPopup;