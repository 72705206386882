import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardTitle, CardImg, CardText, Col, Container, Row, Spinner } from "reactstrap";
import axios from 'axios';
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import MyDeals from "./deals/MyDeals";
import SignIn from "./auth/SignIn";
import { Link } from "react-router-dom";
import DealList from "./deals/DealList";
import PopularDeals from "./deals/PopularDeals";
import PopularCompanies from "./PopularCompanies";
import Banner from "./Banner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderContainer from "./Slider";
import CustomCard from "./Welcome";
import 'normalize.css';
import SearchBar from "./SearchBar";

function Home() {
    const [user, setUser] = useState();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cats, setCategories] = useState([])

    useEffect(() => {
        fetchProducts()
        fetchCat()

        const interval = setInterval(() => {
            const usname = localStorage.getItem("user_logged_in");
            if (usname) {
                setUser(usname);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, [])

    const fetchProducts = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/deals`).
            then(({ data }) => {
                setProducts(data)
                setLoading(true);
            }).catch(function (error) {
            });
    }

    const fetchCat = async () => {
        await axios.get(`https://api.bulkbuyme.com/public/api/categories`).then(({ data }) => {
            setCategories(data);
            setLoading(true); // Add parentheses for setLoading
        })
    }

    return (
        <div style={{ backgroundColor: "#fff", padding: 0, maxWidth: "100%", overflow: "hidden" }}>
            <Container style={{ margin: "0 auto", maxWidth: "100%", padding: "0px" }}>
                <NavBarTop />
                <Row className="title mydeal-box" style={{ marginTop: "14%" }}>
                    <Banner />
                </Row>
                <div className="position-relative">
                    <CustomCard title="Custom Card" content="This is a custom card component." width="80%" height="50px" style={{ top: "70%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </div>
            </Container>
            <Container style={{ backgroundColor: "#fff", padding: 0, maxWidth: "100%", }}>
                <div className="row title">
                    <div className="col-12">
                        <h5>Categories</h5>
                    </div>
                </div>
                <Row className="title mydeal-box" style={{}}>
                    {loading ? (
                        cats.length > 0 &&
                        cats.map((row, key) => (
                            <Col xs={3} md={3} lg={3} style={{ paddingRight: "3px" }}>
                                <Link style={{ textDecoration: "none" }} to={`/comp/${row.id}`}>
                                    <Card className="border-radius-15 shadow" style={{ width: "100%", padding: "0px" }}>
                                        <CardBody style={{ padding: "0px" }}>
                                            <img className="border-radius-15" style={{ width: "100%" }} src={row.image} alt={row.name} />
                                        </CardBody>
                                    </Card>
                                </Link>
                                <div style={{ marginLeft: "0px", marginTop: "5px", textAlign: "center" }}>
                                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>{row.name}</p>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <Spinner animation="border" />
                    )}
                </Row>
                <SearchBar />
                <PopularCompanies />
                <NavBarBottom />
            </Container >
        </div >
    )
}

export default Home;
